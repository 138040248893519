import React from "react"
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import BannerInterior from '../../components/BannerInterior'
import CateringCategory from '../../components/CateringCategory'
import BannerAgenda from '../../components/BannerAgenda'
import video from '../../videos/catering.mp4'

import catpic1 from '../../images/cat-entradas.jpg'
import catpic2 from '../../images/cat-primer-curso.jpg'
import catpic3 from '../../images/cat-plato-fuerte.jpg'
import catpic4 from '../../images/cat-postre.jpg'

const categorias = [
	{
		img: catpic1,
		titulo: 'Entradas',
		url: 'entradas'
	},
	{
		img: catpic2,
		titulo: 'Primer curso',
		url: 'primer-curso'
	},
	{
		img: catpic3,
		titulo: 'Plato fuerte',
		url: 'plato-fuerte'
	},
	{
		img: catpic4,
		titulo: 'Postre',
		url: 'postre'
	},
]

const Catering = () => (
	<Layout>

		<SEO title="Catering" />

		<BannerInterior
			small="Barcelona Eventos"
			title="Catering"
			caption=" "
			videobg={video}
		/>

		<section className="catering-categorias">

			{categorias.map((categoria, index) => (

				<CateringCategory key={index}
					img={categoria.img}
					titulo={categoria.titulo}
					url={categoria.url}
				/>

			))}

		</section>

		<BannerAgenda paddingtop="pt-7"/>

	</Layout>
)

export default Catering
