import React, {Component} from "react"
import { Link } from "gatsby"
import { SimpleImg } from 'react-simple-img'

class BannerAgenda extends Component{
	render(){
		return(
			<div className="item">
				<figure>
					<SimpleImg src={this.props.img} placeholder={false} alt="Agenda tu cita" />
					{/* <img src={categoria.img} alt="Salones Barcelona" /> */}
					<figcaption>
						<Link to={`/catering/${this.props.url}`}>
							<h2>{this.props.titulo}</h2>
							<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
						</Link>
					</figcaption>
				</figure>
			</div>
		)
	}
}

export default BannerAgenda
